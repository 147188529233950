
import { Heading } from "@/components/ui/Heading";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { cn } from "@/lib/utils";
import { ContentfulLivePreview } from "@contentful/live-preview";
import Autoplay from "embla-carousel-autoplay"
import React from "react";
import Image from "next/image";

export interface SocialProofSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    title: string;
    companyLogoCollection: {
        items: ContentfulImageModel[];
    }
}

function aspectRatio(image: ContentfulImageModel) {
    return image.width / image.height
}

const SocialProofSectionComponent: React.FC<SocialProofSectionModel> = (socialProofSectionComponent) => {
    const plugin = React.useRef(
        Autoplay({ jump: false, delay: 2000, stopOnInteraction: true })
    )

    return (
        <div className="social_proof_section flex flex-col items-center w-full py-10 lg:px-6 bg-secondary-200">
            {/* Title */}
            <Heading
                styleVariant={"smallSubHeadline"}
                typeVariant={"h6"}
                {...ContentfulLivePreview.getProps({
                    entryId: socialProofSectionComponent.sys.id,
                    fieldId: 'title',
                    locale: 'en-US',
                })}
                className="text-center mb-5 text-grey-900">
                {socialProofSectionComponent.title}
            </Heading>

            {/* Images */}
            {socialProofSectionComponent.companyLogoCollection && (
                <>
                    <div
                        {...ContentfulLivePreview.getProps({
                            entryId: socialProofSectionComponent.sys.id,
                            fieldId: 'socialProofSection',
                            locale: 'en-US',
                        })}
                        className="hidden max-w-[1440px] lg:flex justify-center items-center overflow-x-auto gap-10 align-middle">
                        {socialProofSectionComponent.companyLogoCollection.items.map((image, index) => (
                            <Image
                                className="max-h-[160px] flex-1 object-contain h-auto min-w-0"
                                key={index}
                                src={image.url}
                                alt={`image-${index}`}
                                width={145}
                                height={145 / aspectRatio(image)}
                            />
                        ))}
                    </div>
                    <div className="flex flex-row lg:hidden">
                        <Carousel plugins={[plugin.current]}>
                            <CarouselContent>
                                {socialProofSectionComponent.companyLogoCollection.items.map((image, index) => (
                                    <CarouselItem key={image.sys.id} className="flex flex-col items-center justify-center max-w-[30%]">
                                        <Image
                                            className="h-[40px]  object-contain min-w-0"
                                            key={index}
                                            src={image.url}
                                            alt={`image-${index}`}
                                            width={145}
                                            height={145 / aspectRatio(image)}
                                        />
                                    </CarouselItem>
                                ))}
                            </CarouselContent>
                        </Carousel>
                    </div>
                </>
            )}



        </div>
    );
};

export default SocialProofSectionComponent;