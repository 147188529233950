import { cn } from "@/lib/utils";
import { Markdown } from "../markdown";
import { ButtonModel } from "@/lib/models/ContenfulSections";
import ButtonComponent from "../ButtonComponent";
import { ToggleGroup } from "@/components/ui/toggle-group";
import { ModeToggleGroupItem } from "@/app/(protected)/(routes)/(external)/platform/freight/_components/ModeSelector";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getBasePath } from "@/app/(protected)/(routes)/(external)/platform/freight/_components/context";
import FreightRatePage from "@/app/(protected)/(routes)/(external)/platform/freight/single/_components/FreightRatePage";
import MultiFreightRatePage from "@/app/(protected)/(routes)/(external)/platform/freight/combo/_components/MultiFreightRatePage";

const getCalculationType = (
    searchParams: ReturnType<typeof useSearchParams>,
    fallback = "single"
) => {
    const fromUrl = searchParams.get("cT");

    if (!fromUrl) {
        return fallback;
    }

    return fromUrl === "combo" ? "combo" : "single";
};

const useCalculationType = (
    searchParams: ReturnType<typeof useSearchParams>
) => {
    const ref = useRef(getCalculationType(searchParams));
    const [ct, setCT] = useState(ref.current);

    useEffect(() => {
        const type = getCalculationType(searchParams, ref.current);

        if (type !== ref.current) {
            ref.current = type;
            setCT(type);
        }
    }, [searchParams]);

    return useMemo(() => ct, [ct]);
};

export interface FreeFRCSectionModel {
    sys: {
        id: string;
    };
    __typename: string;
    isActive: boolean | undefined;
    inactiveTitle: ContentfulRichTextModel | undefined;
    inactiveSubtitle: ContentfulRichTextModel | undefined;
    primaryCta: ButtonModel | undefined;
    secondaryCta: ButtonModel | undefined;
    freeFrcCtaText: ContentfulRichTextModel | undefined;
}

const commonNavigateOptions = {
    scroll: false,
};

const Container = ({ children }: { children: React.ReactNode }) => (
    <div className="py-6 px-6 max-md:px-0">
        <div className="max-w-screen-2xl mx-auto">{children}</div>
    </div>
);

const FreeFRCSectionComponent: React.FC<FreeFRCSectionModel> = (
    freeFRCSectionModel
) => {
    const searchParams = useSearchParams();
    const router = useRouter();

    const pathName = usePathname();

    const calculationType = useCalculationType(searchParams);
    const extraSearchParams = useMemo(() => {
        return {
            cT: calculationType,
        };
    }, [calculationType]);

    const updateSearchParams = useCallback(
        (params: Record<string, string>) => {
            const newParams = new URLSearchParams(searchParams.toString());

            Object.entries(params).forEach(([key, value]) => {
                newParams.set(key, value);
            });

            router.push(`${pathName}?${newParams.toString()}`, commonNavigateOptions);
        },
        [searchParams, router.push, pathName]
    );

    if (freeFRCSectionModel.isActive === false) {
        return (
            <div className="flex flex-col w-full items-center">
                <div className="flex flex-col w-full items-center max-w-[1440px] py-6 lg:py-10 px-6 lg:px-[155px]">
                    {freeFRCSectionModel.inactiveTitle && (
                        <Markdown
                            className={cn("text-center w-full text-secondary-200")}
                            forcedBlock="heading-2"
                            content={freeFRCSectionModel.inactiveTitle}
                        />
                    )}
                    {freeFRCSectionModel.inactiveSubtitle && (
                        <Markdown
                            className={cn("text-center w-full mt-6 text-secondary-200")}
                            forcedBlock="paragraph"
                            bodyVariant="body2"
                            content={freeFRCSectionModel.inactiveSubtitle}
                        />
                    )}
                    <div className="flex flex-row mt-6 gap-6 items-center w-full justify-center">
                        <ButtonComponent
                            contenfulEntryId={freeFRCSectionModel.sys.id}
                            contentfulFieldId={"primaryCta"}
                            buttonVariant={"darkFilled"}
                            buttonSize={"lg"}
                            buttonModel={freeFRCSectionModel.primaryCta}
                        />
                        <ButtonComponent
                            contenfulEntryId={freeFRCSectionModel.sys.id}
                            contentfulFieldId={"secondaryCta"}
                            buttonVariant={"darkOutline"}
                            buttonSize={"lg"}
                            buttonModel={freeFRCSectionModel.secondaryCta}
                        />
                    </div>
                    {freeFRCSectionModel.freeFrcCtaText && (
                        <Markdown
                            className={cn("text-center w-full text-primary-200 mt-2")}
                            forcedBlock="paragraph"
                            bodyVariant="body3"
                            content={freeFRCSectionModel.freeFrcCtaText}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col w-full gap-4">
            <Container>
                <div className="flex items-center justify-center pb-2">
                    <ToggleGroup
                        type="single"
                        className="bg-frGrey-800 rounded-full w-[80%]"
                        value={calculationType}
                        onValueChange={(value) => {
                            updateSearchParams({ cT: value });
                        }}
                        tabIndex={1}
                        size="lg"
                    >
                        <ModeToggleGroupItem value="single" aria-label="Single">
                            Single
                        </ModeToggleGroupItem>
                        <ModeToggleGroupItem value="combo" aria-label="Combo">
                            Combo
                        </ModeToggleGroupItem>
                    </ToggleGroup>
                </div>

                <div id="calc-mobile-fake-header" className="xl:hidden" />

                {calculationType === "combo" ? (
                    <MultiFreightRatePage
                        baseRoute={pathName}
                        extraSearchParams={extraSearchParams}
                        navigateOptions={commonNavigateOptions}
                        formClassName="pt-10"
                        mobileSummaryNodeSelector="#calc-mobile-fake-header"
                    />
                ) : (
                    <FreightRatePage
                        baseRoute={pathName}
                        extraSearchParams={extraSearchParams}
                        navigateOptions={commonNavigateOptions}
                        mobileSummaryNodeSelector="#calc-mobile-fake-header"
                    />
                )}
            </Container>
        </div>
    );
};

export default FreeFRCSectionComponent;
